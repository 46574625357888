import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { tagsApi } from '@/api';

type TagSearchProviderItem = {
    value: string;
    label: React.ReactNode;
};

type LabelRenderer = (tag: string) => React.ReactNode;

export class TagSearchProvider implements ISearchProvider<TagSearchProviderItem> {
    private locale: Domain.Locale;
    private labelRenderer: LabelRenderer = tag => tag;
    private hasMoreResults = false;

    private searchCache: {
        [key: string]: Promise<string[]>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<string[]>;
    } = {};
    private baseByValueCache: {
        [key: string]: string;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setLocale(locale: Domain.Locale) {
        this.locale = locale;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        if (!query) {
            return [];
        }
        const cacheKey = query + '-' + this.locale;
        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = tagsApi.SearchTags(this.locale, query);
        }
        const tags = await this.searchCache[cacheKey];

        return tags.map(this.mapTag);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return undefined;
        }

        if (this.baseByValueCache[value]) {
            return this.mapTag(this.baseByValueCache[value]);
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = tagsApi.SearchTags(this.locale, value);
        }

        const result = await this.byValueCache[value];
        return this.mapTag(result[0]);
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<TagSearchProviderItem>));
    }

    private mapTag = (tag: string): TagSearchProviderItem => {
        return {
            value: tag,
            label: this.labelRenderer(tag),
        };
    };
}
