import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { config } from 'config';
import { useThunkDispatch, CmsItem, maybeSelectLoggedInUser } from 'data-store';
import { Button, Dropdown, Icon, Tx } from 'ui-components';
import { browserStorage } from 'utils';

import PublicAnnouncementsSteamer from '@/Pages/Dashboard/PublicAnnouncements/Components/PublicAnnouncementsSteamer';

import styles from './SteamerTrigger.scss';

export default function SteamerTrigger() {
    const dispatch = useThunkDispatch();
    const isLoggedInAs = useSelector(maybeSelectLoggedInUser);
    const publicAnnouncements = useSelector(CmsItem.publicAnnouncementsOverviewState.maybeSelectPublicAnnouncements)?.total || 0;
    const publicUnreadAnnouncementsTotal = useSelector(CmsItem.publicAnnouncementsOverviewState.selectUnreadPublicAnnouncementsCount);
    const location = useLocation();

    const canSeeSteamer =
        config.featureFlags.enableBeamer &&
        isLoggedInAs &&
        (isLoggedInAs.role === 'platformAdministrator' ||
            isLoggedInAs.role === 'companyAdministrator' ||
            isLoggedInAs.role === 'branchAdministrator' ||
            isLoggedInAs.role === 'supportAgent' ||
            isLoggedInAs.role === 'reseller');

    React.useEffect(() => {
        const readAnnouncements = browserStorage.getItem<string[]>('ReadPublicAnnouncements')?.value || [];
        const setReadAnnouncements = async () => {
            await dispatch(
                CmsItem.publicAnnouncementsOverviewState.setUnreadPublicAnnouncementsCount(publicAnnouncements - readAnnouncements.length),
            );
        };
        setReadAnnouncements();
    }, []);

    return canSeeSteamer ? (
        <Dropdown
            disabled={location.pathname === '/dashboard/lochting-news'}
            headerRenderer={() => (
                <Button
                    variant="secondary"
                    variantSize="xs"
                    className={styles.SteamerTriggerContainer}
                    data-test-id="SteamerTrigger"
                    startIcon={
                        <>
                            <Icon type="action_megaphone" />
                            {publicUnreadAnnouncementsTotal > 0 ? (
                                <Tx
                                    className={styles.SteamerCounter}
                                    level="body-xs"
                                    sx={{
                                        align: 'center',
                                        weight: 'strong',
                                    }}
                                >
                                    {publicUnreadAnnouncementsTotal > 20 ? '20+' : publicUnreadAnnouncementsTotal}
                                </Tx>
                            ) : null}
                        </>
                    }
                ></Button>
            )}
            headerArrow={false}
            body={<PublicAnnouncementsSteamer pageSize={999} />}
            portalClassName="FixedToRight"
            disableFocus={true}
        />
    ) : null;
}
