import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { webshopNewsArticleApi } from '@/api';

type WebshopNewsArticleSearchProviderItem = {
    value: Domain.WebshopNewsArticle['articleId'];
    label: React.ReactNode;
} & Domain.WebshopNewsArticle;

interface Filters {
    activeStatus?: string;
}

type LabelRenderer = (article: Domain.WebshopNewsArticle) => React.ReactNode;

export class WebshopNewsArticleSearchProvider implements ISearchProvider<WebshopNewsArticleSearchProviderItem> {
    private locale: Domain.Locale;
    private webshopId: Domain.Webshop['webshopId'];
    private filters: Filters;
    private labelRenderer: LabelRenderer = article => article.articleId;
    private hasMoreResults = false;

    private baseByValueCache: {
        [key: string]: Domain.WebshopNewsArticle;
    } = {};

    private searchCache: {
        [key: string]: Promise<Domain.WebshopNewsArticlesPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.WebshopNewsArticle | undefined>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setWebshopId(webshopId: Domain.Webshop['webshopId']) {
        this.webshopId = webshopId;
        this.resetCache();
    }

    setLocale(locale: Domain.Locale) {
        this.locale = locale;
        this.resetCache();
    }

    setFilters(filters: Filters) {
        this.filters = filters;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = webshopNewsArticleApi.GetWebshopNewsArticles(
                { page: 1, size: 10 },
                { field: 'name', direction: 'ascending' },
                this.webshopId,
                this.locale,
                query,
                this.filters,
            );

            this.searchCache[query].then(cachedPage => {
                cachedPage.items.forEach(item => {
                    if (!this.byValueCache[item.articleId]) {
                        this.byValueCache[item.articleId] = Promise.resolve(item);
                    }
                });
            });
        }
        const page = await this.searchCache[query];

        return page.items.map(this.mapNewsArticle);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (this.baseByValueCache[value]) {
            return this.mapNewsArticle(this.baseByValueCache[value]);
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = this.maybeGetNewsArticle(value);
        }

        const device = await this.byValueCache[value];
        return device ? this.mapNewsArticle(device) : undefined;
    }

    async byValues(values: string[]) {
        const data = await Promise.all(values.filter(Boolean).map(value => this.byValue(value)));
        return data.filter(Boolean) as WebshopNewsArticleSearchProviderItem[];
    }

    private async maybeGetNewsArticle(newsArticleId: string) {
        try {
            return await webshopNewsArticleApi.GetWebshopNewsArticleDetails(this.webshopId, newsArticleId);
        } catch (e) {
            console.info(e);
            return undefined;
        }
    }

    private mapNewsArticle = (article: Domain.WebshopNewsArticle): WebshopNewsArticleSearchProviderItem => {
        return {
            value: article.articleId,
            label: this.labelRenderer(article),
            ...article,
        };
    };
}
