import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, Tags } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/branch/tags',
        main: () => import('./TagsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: Tags.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/branch/tags/edit/:tagId',
        main: () => import('./UpdateTag'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: Tags.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
