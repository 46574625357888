import * as React from 'react';

import { Route, isBranchManager, isCompanyManager, Device } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/goodie-bag',
        main: () => import('./VendingMachineGoodieBagLandingPage'),
        isAuthorized: [isBranchManager, isCompanyManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/vending-machines/goodie-bag/config',
        main: () => import('./VendingMachineGoodieBagConfig'),
        isAuthorized: [isBranchManager, isCompanyManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/vending-machines/goodie-bag/pickup-codes-overview',
        main: () => import('./VendingMachineGoodieBagPickupCodesOverview'),
        isAuthorized: [isBranchManager, isCompanyManager],
        preloadData: Device.vendingMachineGoodieBagState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
