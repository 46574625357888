import * as React from 'react';

import styles from './Modal.scss';

export default function ModalBody({ style, children }: { style?: React.CSSProperties; children?: React.ReactNode }) {
    return (
        <div
            className={styles.ModalBody}
            style={style}
        >
            {children}
        </div>
    );
}
