import * as React from 'react';

import { Flex, FlexItem } from '@/layout';

import styles from './Modal.scss';

export default function ModalFooter({ children, startItems }: { children?: React.ReactNode; startItems?: React.ReactNode }) {
    return (
        <Flex
            className={styles.ModalFooter}
            gap="8px"
            justifyContent={!startItems ? 'flex-end' : 'flex-start'}
        >
            {startItems ? (
                <FlexItem spaceRight>
                    <Flex gap="8px">{startItems}</Flex>
                </FlexItem>
            ) : null}
            {children}
        </Flex>
    );
}
